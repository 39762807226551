import React from 'react';

function Home() {
    return (
        <section id="home" className="section-home">
            <h1>Welcome to My Portfolio</h1>
            <p>I'm Eduardo Munoz, a Software Engineer.</p>
        </section>
    );
}

export default Home;